import React from "react";

import {
  Box,
  Button,
  IconButton,
  Menu,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { MoreVertIcon } from "@hexocean/braintrust-ui-components/Icons";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import { useRePurchaseUpgradeMutation } from "@js/apps/auth/api";
import { useUser } from "@js/apps/common/hooks";
import { useGetWalletBalanceQuery } from "@js/apps/dashboard/api";
import {
  Section,
  SectionSubtitleMiddleAction,
} from "@js/apps/settings/forms/helpers";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";

interface CustomError {
  data?: {
    error_message?: string;
  };
}

const UpgradeSection = (): JSX.Element => {
  const [rePurchaseUpgrade] = useRePurchaseUpgradeMutation();

  const user = useUser();
  const dispatch = useAppDispatch();

  const userBalanceId = Number(user?.balance_holder);
  const { refetch: userBalanceRefetch } = useGetWalletBalanceQuery(
    { balanceHolderId: userBalanceId },
    { skip: user?.freelancer_hide_token_balance || !userBalanceId },
  );

  const handleColorOnStatus = (status: string) => {
    if (status === "ACTIVE") {
      return "#447513";
    } else if (status === "WARNING") {
      return "var(--dark-orange)";
    } else if (status === "EXPIRED") {
      return "var(--negative-2)";
    } else {
      return "#676460";
    }
  };

  const handleBgOnStatus = (status: string) => {
    if (status === "ACTIVE") {
      return "var(--soft-green)";
    } else if (status === "WARNING") {
      return "var(--soft-orange)";
    } else if (status === "EXPIRED") {
      return "var(--soft-red)";
    } else {
      return "var(--soft-grey)";
    }
  };

  const statusBox = (type: string) => {
    const data = user?.upgrade_purchase_status?.[type];
    const status: string = data?.status ? data?.status : "";
    const color = handleColorOnStatus(status);
    const bg = handleBgOnStatus(status);
    return (
      <Box bgcolor={bg} px={2} borderRadius={10} py={0.5}>
        <Typography
          component="p"
          sx={{ color: color }}
          size="medium"
          fontWeight={500}
        >
          {data?.usage}
        </Typography>
      </Box>
    );
  };

  const rePurchaseHandler = async (type: string) => {
    try {
      const payload: "boost" | "insights" =
        type === "insights" ? "insights" : "boost";
      await rePurchaseUpgrade({
        upgrade_type: payload,
      })?.unwrap();

      userBalanceRefetch();
      dispatch(fetchCurrentUser());
    } catch (err) {
      const error = err as CustomError;
      Snackbar.error(error?.data?.error_message || "An error occurred");
    }
  };

  const actionButton = (type: string) => {
    return (
      <Menu
        anchor={
          <IconButton
            aria-label={`Open ${type} upgrades menu`}
            aria-controls={`job-action-menu-old`}
            aria-haspopup="true"
            variant="transparent"
            size="small"
          >
            <Box
              bgcolor={"var(--off-white)"}
              borderRadius={"100%"}
              px={1}
              py={0.8}
            >
              <MoreVertIcon />
            </Box>
          </IconButton>
        }
      >
        <Menu.Item
          onClick={() => rePurchaseHandler(type)}
          key={"label"}
          tooltipText={"reason"}
        >
          Purchase additional {type === "insights" ? "days" : "boosts"}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
      <Section title="Upgrades">
        <SectionSubtitleMiddleAction
          title={"Application Insights"}
          description={
            "Identify promising opportunities and refine your job search with personalized feedback."
          }
          action={
            <Box display={"flex"} alignItems={"center"}>
              <Box> {statusBox("insights")} </Box>
              {actionButton("insights")}
            </Box>
          }
          {...(user?.upgrade_purchase_status?.insights?.status !== "ACTIVE" && {
            endButton: (
              <Button
                onClick={() => rePurchaseHandler("insights")}
                shape="squared"
                sx={{ minWidth: "18px !important" }}
                variant={
                  user?.upgrade_purchase_status?.insights?.status
                    ? "primary"
                    : "secondary"
                }
              >
                Purchase Application Insights
              </Button>
            ),
          })}
          divider
        />

        <SectionSubtitleMiddleAction
          title={"Application Boost"}
          description={
            "Get to the top of the applicant list to increase your chances of getting hired."
          }
          action={
            <Box>
              <Box display={"flex"} alignItems={"center"}>
                <Box> {statusBox("boost")} </Box>
                {actionButton("boost")}
              </Box>
            </Box>
          }
          {...(user?.upgrade_purchase_status?.boost?.status !== "ACTIVE" && {
            endButton: (
              <Button
                onClick={() => rePurchaseHandler("boost")}
                variant={
                  user?.upgrade_purchase_status?.boost?.status
                    ? "primary"
                    : "secondary"
                }
                shape="squared"
                sx={{ minWidth: "18px !important" }}
              >
                Purchase Application Insights
              </Button>
            ),
          })}
        />
      </Section>
    </>
  );
};

export default UpgradeSection;
