import type { FC } from "react";
import React, { useMemo } from "react";
import { omit } from "underscore";

import type { AvatarSrc } from "@hexocean/braintrust-ui-components";
import {
  Avatar,
  Box,
  Button,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowRightIcon,
  CheckIcon,
  CloseIcon,
  VerifiedIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { useGetJobApplicationInsightsQuery } from "@js/apps/jobs/api";
import { usePurchaseUpgrade } from "@js/hooks/purchase-upgrade";

import {
  formatMatchLabel,
  getMatchLabelColors,
  useMatchPlacement,
} from "../utils";

type InsightTooltipProps = {
  isApplied: boolean;
  jobId: number;
  matchLabel: EnumType<typeof ENUMS.SmartMatchingLabel> | null;
};

const PREVIEW_INSIGHTS = [
  "Selectively applying to jobs right now",
  "Top candidate for multiple past job positions",
];

export const InsightTooltip: FC<InsightTooltipProps> = ({
  isApplied,
  jobId,
  matchLabel,
}) => {
  const { onClickPurchaseUpgrade } = usePurchaseUpgrade("insights");
  const user = useUser();
  const avatarSrc = useMemo(
    () => omit(user, "account_type") as AvatarSrc,
    [user],
  );
  const { isLoading, data } = useGetJobApplicationInsightsQuery({ id: jobId });
  const hasTopSkills = !data?.skills.includes("0") || false;
  const [currentSkills, totalSkills] = data?.skills.split("/") || [0, 0];
  const matchLabelStyleProps = getMatchLabelColors(matchLabel);
  const canViewInsights = useMemo(
    () => user?.can_view_insights,
    [user?.can_view_insights],
  );
  const isFreelancerApproved = useMemo(
    () => user?.freelancer_approved,
    [user?.freelancer_approved],
  );
  const matchPlacement = useMatchPlacement(
    matchLabel,
    data?.total_applicants || 0,
    isApplied,
  );
  const showInsightContent =
    canViewInsights && Array.isArray(data?.match_summary);
  const showPreview = useMemo(
    () => !user?.can_view_insights && user?.freelancer_approved,
    [user?.can_view_insights, user?.freelancer_approved],
  );

  return (
    <Box>
      <Box display="flex" mb={1.25} gap={1.25}>
        <Box display="flex" alignItems="center">
          <Box height="29px" width="29px" position="relative">
            <VerifiedIcon
              sx={{
                top: 0,
                left: 0,
                position: "absolute",
                alignSelf: "end",
                fontSize: "36.33px",
                zIndex: 2,
              }}
            />
          </Box>
          <Avatar
            src={avatarSrc}
            sx={{ height: 29, width: 29, marginLeft: -0.1, zIndex: 1 }}
          />
        </Box>
        <Box
          sx={{
            width: "fit-content",
            backgroundColor: matchLabelStyleProps.backgroundColor,
          }}
          borderRadius={9}
          py={0.75}
          px={1.5}
        >
          <Typography
            component="p"
            variant="label"
            size="small"
            sx={{
              color: matchLabelStyleProps.textColor,
            }}
          >
            You're a{matchLabel === ENUMS.SmartMatchingLabel.OK ? "n" : ""}{" "}
            <span
              style={{
                textTransform:
                  matchLabel === ENUMS.SmartMatchingLabel.OK
                    ? "inherit"
                    : "lowercase",
              }}
            >
              {formatMatchLabel(matchLabel)}
            </span>{" "}
            for this job!
          </Typography>
        </Box>
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <Box display="flex" flexDirection="column" gap={1.5}>
          {isFreelancerApproved && (
            <Box display="flex" gap={1}>
              {hasTopSkills ? (
                <CheckIcon
                  sx={{ fontSize: "20px", color: "var(--positive-2)" }}
                />
              ) : (
                <CloseIcon
                  sx={{ fontSize: "20px", color: "var(--negative-2)" }}
                />
              )}
              <Typography
                sx={{ color: "var(--black)" }}
                component="p"
                variant="paragraph"
                size="small"
              >
                {currentSkills} of {totalSkills} top skills for this job
              </Typography>
            </Box>
          )}
          {matchPlacement?.label && canViewInsights && (
            <Box display="flex" gap={1}>
              {matchPlacement.summaryType === "positive" ? (
                <CheckIcon
                  sx={{ fontSize: "20px", color: "var(--positive-2)" }}
                />
              ) : (
                <CloseIcon
                  sx={{ fontSize: "20px", color: "var(--negative-2)" }}
                />
              )}
              <Typography
                sx={{ color: "var(--black)" }}
                component="p"
                variant="paragraph"
                size="small"
              >
                {matchPlacement.label}
              </Typography>
            </Box>
          )}
          {showInsightContent ? (
            data?.match_summary?.length !== 0 ? (
              data?.match_summary?.map(({ sentence, summary_type }) => (
                <Box key={sentence} display="flex" gap={1}>
                  {summary_type === "positive" ? (
                    <CheckIcon
                      sx={{ fontSize: "20px", color: "var(--positive-2)" }}
                    />
                  ) : (
                    <CloseIcon
                      sx={{ fontSize: "20px", color: "var(--negative-2)" }}
                    />
                  )}
                  <Typography
                    sx={{ color: "var(--black)" }}
                    component="p"
                    variant="paragraph"
                    size="small"
                  >
                    {sentence}
                  </Typography>
                </Box>
              ))
            ) : (
              <Box display="flex" gap={1}>
                <img
                  height="20px"
                  width="auto"
                  src={`${SETTINGS.STATIC_URL}jobs/cloud-crossed.svg`}
                  alt="cloud-crossed"
                />
                <Typography
                  sx={{ color: "var(--black)" }}
                  component="p"
                  variant="paragraph"
                  size="small"
                >
                  Please wait while we cook up more insights.
                </Typography>
              </Box>
            )
          ) : null}
          {showPreview && (
            <Box
              sx={{
                position: "relative",
                "& > ::after": {
                  content: "''",
                  position: "absolute",
                  inset: 0,
                  backdropFilter: "blur(2.5px)",
                  "-webkit-backdrop-filter": "blur(2.5px)",
                  pointerEvents: "none",
                  background: "rgba(255, 255, 255, 0.5)",
                },
              }}
            >
              <Box display="flex" flexDirection="column" gap={1.5}>
                {PREVIEW_INSIGHTS.map((preview) => (
                  <Box key={preview} display="flex" gap={1}>
                    {matchLabel !== ENUMS.SmartMatchingLabel.BAD ? (
                      <CheckIcon
                        sx={{ fontSize: "20px", color: "var(--positive-2)" }}
                      />
                    ) : (
                      <CloseIcon
                        sx={{ fontSize: "20px", color: "var(--negative-2)" }}
                      />
                    )}
                    <Typography
                      sx={{ color: "var(--black)", userSelect: "none" }}
                      component="p"
                      variant="paragraph"
                      size="small"
                    >
                      {preview}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {!canViewInsights && (
            <Button
              onClick={onClickPurchaseUpgrade}
              shape="squared"
              variant="positive-2"
              size="x-small"
              endIcon={
                <ArrowRightIcon
                  sx={{
                    "& > path": {
                      strokeWidth: "2.5px !important",
                    },
                  }}
                />
              }
              sx={{
                boxShadow: "0px 4px 11px 0px #0000001A",
                paddingX: "12px !important",
                maxWidth: { xs: "unset", sm: "240px" },
                marginTop: isFreelancerApproved ? 0 : 1,
                marginLeft: { xs: "unset", sm: 1.5 },
                "&.typography": {
                  fontWeight: "500 !important",
                },
                ...(showPreview && {
                  marginTop: -5,
                }),
              }}
            >
              Unlock full Application Insights
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};
