import React, { Fragment, type ReactNode, useEffect, useState } from "react";
import cs from "classnames";

import type {
  ButtonTileOption,
  ButtonTileSelectProps,
  OptionTileButtonProps,
  Variants,
} from "@hexocean/braintrust-ui-components";
import {
  Box,
  FormHelperText,
  OptionTileButton,
  Tooltip,
  useButtonSelect,
} from "@hexocean/braintrust-ui-components";
import { CheckCircleFilledIcon } from "@hexocean/braintrust-ui-components/Icons";
import { isNotNullable } from "@js/utils";

export type OptionTileButtonsProps<T extends ButtonTileOption> =
  ButtonTileSelectProps<T> & {
    renderCustomOptionButton?: RenderCustomOptionButtonFun;
    className?: string;
    buttonClassName?: string;
    hideError?: boolean;
    selectedClassName?: string;
    variant?: EnumType<typeof Variants>;
    selectedVariant?: EnumType<typeof Variants>;
    disabled?: boolean;
    renderOptionButton?: RenderOptionButtonFun<T>;
  };

type RenderOptionButtonArgs<TOption> = {
  defaultElement: React.ReactElement;
  option: TOption;
  disabled: boolean;
};
type RenderOptionButtonFun<TOption> = (
  args: RenderOptionButtonArgs<TOption>,
) => JSX.Element;

export type RenderCustomOptionButtonArgs = Pick<
  ReturnType<typeof useButtonSelect>,
  "itemA11yProps" | "handleChange"
> & {
  isSelected: boolean;
  onClick: () => void;
  setActiveIndex: (index: number) => void;
};

type RenderCustomOptionButtonFun = (
  args: RenderCustomOptionButtonArgs,
) => JSX.Element | undefined;

export const OptionTileButtons = <
  T extends ButtonTileOption & { disabledTooltipTitle?: string },
>({
  className,
  buttonClassName,
  value,
  onChange,
  error,
  hideError = false,
  options,
  selectedClassName,
  renderCustomOptionButton,
  canSingleValueBeEmpty,
  variant = "white-violet",
  selectedVariant = "violet",
  disabled,
  renderOptionButton,
}: OptionTileButtonsProps<T>) => {
  const { itemA11yProps, containerA11yProps, handleChange, isSelected } =
    useButtonSelect({
      value,
      onChange,
      canSingleValueBeEmpty,
    });
  const [activeIndex, setActiveIndex] = useState<number | undefined>(() => {
    const indexOfPreselectedOption = options.findIndex((option) =>
      isSelected(option),
    );
    return indexOfPreselectedOption === -1
      ? undefined
      : indexOfPreselectedOption;
  });
  const [isCustomOptionActive, setIsCustomOptionActive] =
    useState<boolean>(false);

  const showError = error && !hideError;

  useEffect(() => {
    if ((!isNotNullable(value) || value === "") && !isCustomOptionActive) {
      setActiveIndex(undefined);
    }
  }, [isCustomOptionActive, value]);

  return (
    <Box>
      <div
        className={cs("option-tile-buttons-wrapper", className)}
        {...containerA11yProps}
      >
        {options.map((option, index) => {
          const isCurrentOptionSelected =
            activeIndex === index ||
            (isSelected(option) && !isCustomOptionActive);
          const currentOptionDisabled = !!(option.disabled !== undefined
            ? option.disabled
            : disabled);

          const key = JSON.stringify(option.value);
          const button = (
            <CustomOptionTileButton
              key={key}
              {...itemA11yProps(isCurrentOptionSelected)}
              option={{
                value: option.value,
                label: (
                  <Box display={"flex"} alignItems={"center"}>
                    {isCurrentOptionSelected && (
                      <Box mr={1}>
                        <CheckCircleFilledIcon fontSize="medium" />
                      </Box>
                    )}
                    {option.label}{" "}
                    {/* Assuming `option` has a `label` property */}
                  </Box>
                ),
              }}
              isSelected={isCurrentOptionSelected}
              disabled={currentOptionDisabled}
              selectedClassName={selectedClassName}
              variant={isCurrentOptionSelected ? selectedVariant : variant}
              className={buttonClassName}
              handleChange={(val) => {
                setActiveIndex((prevActiveIndex) => {
                  return prevActiveIndex === index ? undefined : index;
                });
                handleChange(val);
                setIsCustomOptionActive(false);
              }}
            />
          );

          if (renderOptionButton) {
            return (
              <Fragment key={key}>
                {renderOptionButton({
                  defaultElement: button,
                  option,
                  disabled: currentOptionDisabled,
                })}
              </Fragment>
            );
          }

          if (
            option.tooltipTitle ||
            (option.disabledTooltipTitle && option.disabled)
          ) {
            return (
              <Tooltip
                key={key}
                title={option.tooltipTitle || option.disabledTooltipTitle || ""}
                placement="top"
              >
                <span>{button}</span>
              </Tooltip>
            );
          }

          return <div key={key}>{button}</div>;
        })}
        {renderCustomOptionButton &&
          renderCustomOptionButton({
            isSelected: activeIndex === options.length,
            onClick: () => {
              setActiveIndex(options.length);
              setIsCustomOptionActive(true);
            },
            setActiveIndex,
            itemA11yProps,
            handleChange,
          })}
      </div>

      {showError && (
        <Box mt={1}>
          <FormHelperText error>{error}</FormHelperText>
        </Box>
      )}
    </Box>
  );
};

export const CustomOptionTileButton = ({
  option,
  handleChange,
  isSelected,
  disabled,
  variant,
  className,
  ...props
}: OptionTileButtonProps) => {
  const Icon = option.icon;
  return (
    <OptionTileButton
      className={cs("option-tile-button", className)}
      handleChange={handleChange}
      option={{
        ...option,
        label: (
          <CustomOptionTileButtonLabelContainer>
            {!!Icon && (
              <Box mr={0.5} mb={0.5}>
                <Icon />
              </Box>
            )}
            {option.label}
          </CustomOptionTileButtonLabelContainer>
        ),
      }}
      isSelected={isSelected}
      variant={variant || "white-violet"}
      disabled={disabled}
      {...props}
    />
  );
};

const CustomOptionTileButtonLabelContainer = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      className="option-tile-label"
    >
      {children}
    </Box>
  );
};
